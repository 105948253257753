<template>
  <div id="app">
    <header class="bg content">
      <div class="header-left">
        <p class="logo"><router-link to="/">Maison Magenta</router-link></p>
        <h1>
          Antiquités - Brocante - Galerie d'art、Artisanat d’art
        </h1>
        <div>Showroom "Antiquités MM" : site Proantic en ligne→<a href="https://www.proantic.com/galerie/antiquites-mm/" class="underline" target="_blank">ANTIQUITES MM</a></div>
      </div>
      <nav class="hide-mobile">
        <router-link to="/">Accueil</router-link>
        <router-link to="/expositions">Expositions</router-link>
        <router-link to="/presse">Presse</router-link>
      </nav>
      <div class="mobile-nav hide-desktop">
        <div
          class="mobile-nav__button"
          :class="{ open: isOpen }"
          @click="mobileOpen"
        >
          <span class="burger line-1"></span>
          <span class="burger line-2"></span>
          <span class="burger line-3"></span>
        </div>
        <div class="mobile-nav__nav bg" :class="{ open: isOpen }">
          <router-link to="/">Accueil</router-link>
          <router-link to="/expositions">Expositions</router-link>
          <router-link to="/presse">Presse</router-link>
        </div>
      </div>
    </header>
    <router-view :key="$route.fullPath" />
    <section id="store" class="bg content">
      <div id="map">
        <iframe 
          title="Google Map : 17 Place Maréchal Lyautey 69006 LYON, FRANCE"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11132.630108531872!2d4.835795490505723!3d45.76803318097574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47f4eaf739d5908d%3A0x9d65f66e16d1a46d!2s17%20Pl.%20Mar%C3%A9chal%20Lyautey%2C%2069006%20Lyon!5e0!3m2!1sfr!2sfr!4v1737823996624!5m2!1sfr!2sfr" 
          width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
        </iframe>
      </div>
      <div id="hours">
        <address>
          <p class="h1">Adresse</p>
          <h2 class="h2">17 Place Maréchal Lyautey<br />69006 LYON, FRANCE</h2>
          <p class="sns">
            <a href="mailto:contact@maisonmagenta.fr" class="icon email"></a>
            <a
              href="https://www.instagram.com/maison.magenta/"
              class="icon insta"
              target="_blank"
              rel="noopener"
            ></a>
            <a
              href="https://www.facebook.com/maison.magenta.france/"
              class="icon facebook"
              target="_blank"
              rel="noopener"
            ></a>
          </p>
        </address>
        <!--
        <p class="h1">Horaires d'ouverture</p>
        <ul>
          <li>Lundi, Jeudi et Vendredi<br>10:30–13:00, 15:00–18:30</li>
          <li>Mercredi<br>10:00–12:00, 15:30–18:30</li>
          <li>Samedi<br>10:30–12:30, 14:30–17:30</li>
          <li>* Mardi/Dimanche Sur RDV</li>
        </ul> -->
      </div>
    </section>
    <footer>
      <router-link to="/mentions">Mentions légales</router-link>
      <span>Copyright {{ currentDateTime() }} &nbsp;&copy;Maison Magenta</span>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    currentDateTime() {
      const current = new Date();
      const date = current.getFullYear();
      return date;
    },
    mobileOpen() {
      this.isOpen = !this.isOpen;
      // some code to filter users
    },
  },
  watch: {
    // eslint-disable-next-line
    $route(to, from) {
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;500&display=swap");

$gray: #bababa;
$sub: #999999;
$magenta: #b90070;

html {
  font-size: 62.5%;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: white;
  font-family: "Cormorant Garamond", serif;
  font-size: 1.6rem;
  align-items: center;
  background: rgb(185, 0, 112);
  background: linear-gradient(
    90deg,
    rgba(185, 0, 112, 1) 0%,
    rgb(185, 0, 69) 60%,
    rgb(92, 0, 40) 100%
  );
}
#app {
  position: relative;
  padding-bottom: 6rem;
  background: url("~@/assets/bg.svg");
  * {
    box-sizing: border-box;
  }
}
a {
  color: white;
  text-decoration: none;
  transition: all 0.3s;
  &:hover {
    opacity: 0.7;
  }
}
.underline {
  text-decoration: underline;
}
.bg {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.bg-white {
  position: relative;
  background-color: white;
  color: black;
  padding-bottom: 3rem;
  overflow: hidden;
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -5%;
    right: -50%;
    width: 100%;
    height: 100%;
    background-image: url("~@/assets/bg.svg");
    filter: grayscale(100%);
    opacity: 0.04;
    pointer-events: none;
  }
  &.no-bg::before {
    display: none;
  }
}
header {
  margin: 1.5rem auto 2rem auto;
  display: flex;
  justify-content: space-between;
}

nav {
  text-transform: uppercase;
  display: flex;
  font-size: 1.4rem;
}
nav a {
  padding: 1rem;
  position: relative;
}
nav a::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  width: calc(100% - 2rem);
  height: 0.2rem;
  background-color: transparent;
  left: 1rem;
  transition: all 0.3s;
}
.router-link-exact-active::after {
  background-color: white;
}
.mobile-nav__button {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 0.5rem;
  width: 2.8rem;
  height: 2.8rem;
  border: 1px solid rgba(white, 0.3);
  border-radius: 0.4rem;
  margin-left: 1.5rem;
  .burger {
    content: "";
    display: block;
    width: 100%;
    height: 0.1rem;
    background-color: white;
    margin: auto;
    transition: all 0.3s;
  }
  &.open {
    display: block;
    border-radius: 50%;
    .burger {
      &.line-2 {
        display: none;
      }
      &.line-1 {
        transform: rotate(45deg);
        margin-top: 7px;
      }
      &.line-3 {
        transform: rotate(-45deg);
        margin-top: -1px;
      }
    }
  }
}
.mobile-nav__nav {
  position: absolute;
  top: 7.5rem;
  left: 0;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  box-sizing: border-box;
  text-transform: uppercase;
  font-size: 1.4rem;
  z-index: 10;
  background: rgb(185, 0, 112);
  background: linear-gradient(
    90deg,
    rgba(185, 0, 112, 1) 0%,
    rgb(185, 0, 69) 60%,
    rgb(92, 0, 40) 100%
  );
  transition: max-height 0.3s;
  &.open {
    max-height: 14rem;
  }
}
.mobile-nav__nav a {
  display: block;
  width: 100%;
  background: rgb(185, 0, 112);
  background: linear-gradient(
    90deg,
    rgba(185, 0, 112, 1) 0%,
    rgb(185, 0, 69) 60%,
    rgb(92, 0, 40) 100%
  );
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem 0 0.5rem 1rem;
  border-left: 0.2rem solid transparent;
  border-bottom: 0.07rem dotted rgba(white, 0.3);
  &:last-child {
    border-bottom: 0;
  }
}
.mobile-nav__nav a.router-link-exact-active {
  border-left-color: white;
}
.logo {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 3rem;
  margin: 0 0 0.5rem 0;
  padding: 0;
  line-height: 1;
}
h1,
.h1 {
  font-weight: 400;
  font-size: 1.2rem;
  margin: 0;
  padding: 0;
}
.h2 {
  font-size: 1.8rem;
  font-weight: 400;
  margin-top: 0;
}
address {
  font-style: normal;
  margin: auto;
}
.section-title {
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 1px solid $gray;
  font-size: 2.2rem;
  padding-bottom: 1.5rem;
  margin-top: 4rem;
  &.now {
    font-size: 1.8rem;
  }
}
h3,
h4 {
  font-weight: 400;
}

h3 {
  margin-bottom: 0;
}
h4 {
  margin-top: 1rem;
}
h4 a {
  color: white;
  text-decoration: none;
  transition: all 0.4s;
  &:hover {
    opacity: 0.6;
  }
}

#galerie {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  li {
    margin: 0;
    padding: 0;
    aspect-ratio: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .over {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(black, 0.6);
      color: white;
      display: flex;
      padding: 2rem;
      line-height: 1.4;
      font-weight: 200;
      opacity: 0;
      transition: opacity, 0.4s;
      overflow: auto;
    }
    &:hover {
      .over {
        opacity: 1;
      }
    }
  }
}
#store {
  display: flex;
  flex-direction: column;
  margin: 3rem auto 0 auto;
}
#map {
  width: 100%;
  height: 40rem;
  padding: 0.8rem 1rem 0.6rem 1rem;
  border: 0.1rem solid rgba(white, 0.7);
  box-sizing: border-box;
  order: 1;
  margin-top: 2rem;
}
#hours {
  .h1 {
    font-size: 1.8rem;
    margin-bottom: 1.2rem;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      display: flex;
      justify-content: start;
      margin-bottom: 0.5em;
      > *:first-child {
        text-transform: capitalize;
        width: 9rem;
        margin-right: 2rem;
        text-align: right;
      }
      > *:nth-child(2) {
        width: 20rem;
        text-align: left;
      }
    }
  }
}
#hours ul li > *:nth-child(2),
#galerie {
  font-size: 1.8rem;
}
.hide {
  display: none;
}

.sns {
  display: flex;
}
.icon {
  border-radius: 50%;
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 1rem;
}
.email {
  background: white url("assets/icon-email.svg") no-repeat center center;
}
.insta {
  background: white url("assets/icon-instagram.svg") no-repeat center center;
}
.facebook {
  background: white url("assets/icon-facebook.svg") no-repeat center center;
}
footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgb(42 17 24);
  text-align: center;
  padding: 0.6rem;
  color: rgba(255, 255, 255, 0.7);
  a {
    margin-right: 3rem;
    color: rgba(255, 255, 255, 0.7);
    &:hover {
      color: rgba(255, 255, 255, 1);
      opacity: 1;
    }
  }
}
/* Expositions */
.two-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}
.image {
  border: 1px solid $gray;
  img {
    max-width: 100%;
  }
}
.post {
  display: block;
  font-weight: 700;
  margin: 0.3rem 0;
}
.text a {
  color: black;
}
.title, .title a {
  color: $magenta;
  font-weight: 600;
  font-style: italic;
  font-size: 2.5rem;
}
.name {
  font-size: 2.2rem;
}
.note {
  font-size: 1.8rem;
}
.intro {
  p:not(.intro__title) {
    margin: 0.5rem 0;
  }
}
.intro__title {
  font-size: 2rem;
}
@media (max-width: 340px) {
  .logo {
    font-size: 2.5rem;
  }
}
@media (min-width: 370px) {
  h1,
  .h1 {
    font-size: 1.4rem;
  }
}
@media (min-width: 400px) {
  .bg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  header {
    margin-top: 2rem;
  }
}
@media (min-width: 400px) {
  header {
    top: 7.2rem;
  }
}
@media (min-width: 500px) {
  #galerie {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }
}
@media (min-width: 600px) {
  header {
    margin-top: 2rem;
    margin-bottom: 3rem;
  }
  address {
    margin-bottom: 3rem;
  }
  .bg-white {
    padding-bottom: 6rem;
  }
  .section-title {
    font-size: 2.4rem;
    padding-bottom: 2rem;
    margin-top: 6rem;
    &.now {
      font-size: 2rem;
    }
  }
  .two-column {
    flex-direction: row;
    .image {
      flex-shrink: 0;
      width: 30%;
    }
    .text {
      margin-left: 5rem;
    }
    .note {
      font-size: 2rem;
    }
  }
}
@media (min-width: 640px) {
  .logo {
    font-size: 3.5rem;
    margin-bottom: 0.7rem;
  }
  footer {
    font-size: 1.4rem;
  }
  #store {
    flex-direction: row;
    margin: 6rem auto 0 auto;
    align-items: center; 
  }
  #map {
    width: 80%;
    height: 60rem;
    order: 0;
  }
  #hours {
    margin-top: 2rem;
    margin-left: 2rem;
  }
}
@media (max-width: 959px) {
  header {
    align-items: center;
  }
  .hide-mobile {
    display: none;
  }
}
@media (min-width: 960px) {
  body {
    font-size: 1.6rem;
  }
  header {
    align-items: baseline;
  }
  nav {
    font-size: 1.4rem;
  }
  .bg {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .hide-desktop {
    display: none;
  }

  header {
    margin-top: 3rem;
    margin-bottom: 4rem;
  }
  .logo {
    font-size: 4.2rem;
    margin-bottom: 0.7rem;
  }
  h1,
  .h1 {
    font-size: 1.6rem;
  }
  #galerie {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
  }
  #hours {
    margin-left: 4rem;
  }
  #hours ul li > *:nth-child(2),
  #galerie {
    font-size: 1.8rem;
  }
  .intro {
    padding: 3rem 0;
  }
  .intro__title {
    font-size: 3rem;
  }
  .intro {
    p:not(.intro__title) {
      margin: 1rem 0;
    }
  }
}
@media (min-width: 1200px) {
  body {
    font-size: 1.8rem;
  }
  nav {
    font-size: 1.6rem;
  }
  .logo {
    font-size: 5.2rem;
  }
  h1,
  .h1,
  #hours .h1 {
    font-size: 2rem;
  }
  .h2 {
    font-size: 2rem;
  }
  .content {
    max-width: 140rem;
    margin-left: auto;
    margin-right: auto;
  }
  #hours ul li > *:nth-child(2),
  #galerie {
    font-size: 2rem;
  }
  #hours ul li > *:nth-child(2) {
    line-height: 1.333;
  }
  .section-title {
    font-size: 2.4rem;
    padding-bottom: 2rem;
    margin-top: 8rem;
  }
  .two-column {
    margin-top: 4rem;
  }
}
</style>
